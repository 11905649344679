import React, { useLayoutEffect, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import cn from 'classnames'
import { mediaXsOnly, mediaMaxSm } from '@gymondo/frontend-core/style-vars'
import { useMediaQuery } from '@gymondo/frontend-core/hooks'
import { graphql } from 'gatsby'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { FluidWrapper, ScrollTopArrow } from '@gymondo/frontend-core/components'
import { LANGUAGES } from '@gymondo/frontend-core/enums'
import {
    setLoginModalChangePassword,
    openLoginModal,
} from '../../../service/page-header'
import { setConfig } from '../../../service/layout'
import { BackgroundImage, Cta } from '../../../component'
import ProgramGrid from './component/program-grid'
import { QUERY_PARAMS } from '../../../config/definitions'
import { useExperiment } from '../../../hook/useExperiment/use-experiment'

import style from './home.module.scss'

const renderHero = (img, isXs, loaded, locale, isTestVariant) => (
    <FluidWrapper
        wrapperClassName={style.heroContentWrapper}
        className={style.heroContent}
    >
        <BackgroundImage
            classParentName={style.heroContainer}
            childrenClassName={cn(
                style.heroChildContainer,
                isXs &&
                    locale === LANGUAGES.ENGLISH &&
                    style.heroChildContainerNoSeal
            )}
            fluid={img}
            imgClassName={style.imgClassName}
            gradient
            bottomGradient={isTestVariant && isXs}
        >
            <div className={style.heroTextWrapper}>
                <h1 className={cn(style.heroText, style.title, 'gym-h3')}>
                    <div>
                        <FormattedMessage id="home.hero.title.1" />
                    </div>
                    <div>
                        <FormattedMessage id="home.hero.title.2" />
                    </div>
                </h1>
                {loaded && (
                    <>
                        <div className={cn(style.heroText, style.subtitle)}>
                            <FormattedMessage
                                id={
                                    isXs
                                        ? 'home.hero.subtitle.mobile'
                                        : 'home.hero.subtitle'
                                }
                            />
                        </div>
                        <Cta displayXS fixedXS />
                    </>
                )}
            </div>
        </BackgroundImage>
    </FluidWrapper>
)

const renderFooter = (img) => (
    <FluidWrapper className={cn(style.heroContent, style.footerContent)}>
        <BackgroundImage fluid={img} imgClassName={style.footerImgClassName}>
            <div className={style.footerTextWrapper}>
                <h4 className={style.footerTitle}>
                    <FormattedMessage
                        id="success.footer.title"
                        values={{
                            wrapper: (...msg) => <span>{msg}</span>,
                        }}
                    />
                </h4>
                <div className={style.subtitle}>
                    <FormattedMessage id="success.footer.subtitle" />
                </div>
                <Cta fixedXS={false} />
            </div>
        </BackgroundImage>
    </FluidWrapper>
)

const renderProgramsGrid = (localePrograms) => (
    <div>
        <div className="wrapper">
            <ProgramGrid preFetchedPrograms={localePrograms} />
        </div>
    </div>
)

const getNodeName = (node, isVariant) => {
    return `${
        isVariant ? `${node}Test` : node
    }.edges.0.node.childImageSharp.fluid`
}

/**
 * Home page
 */
export const Home = ({
    data,
    pageContext,
    location,
    setLoginModalChangePasswordAction,
    openLoginModalAction,
    setLayoutConfig,
    user,
}) => {
    const experimentVariant = useExperiment(
        process.env.GATSBY_HOMEPAGE_IMAGE_EXPERIMENT
    )

    const isMaxSm = useMediaQuery(mediaMaxSm)
    const isXs = useMediaQuery(mediaXsOnly)
    const { locale } = pageContext
    const programs = _.groupBy(_.get(data, 'allProgram.nodes'), 'locale')
    const localePrograms = programs[locale] || []

    const isTestVariant = experimentVariant === '1'

    let heroImg = {}
    if (experimentVariant !== null) {
        heroImg = isXs
            ? _.get(data, getNodeName('headerMobile', isTestVariant))
            : _.get(data, getNodeName('header', isTestVariant))
    }

    let footerImg = _.get(data, getNodeName('footer', isTestVariant))
    if (isXs) {
        footerImg = _.get(data, getNodeName('footerMobile', isTestVariant))
    } else if (isMaxSm) {
        footerImg = _.get(data, getNodeName('footerTablet', isTestVariant))
    }

    useLayoutEffect(() => {
        setLayoutConfig({
            className: style.layout,
            textColorVariant: 'white',
        })
    }, [setLayoutConfig])

    useLayoutEffect(() => {
        const queryParams = qs.parse(location.search)
        if (user.isLoggedIn && queryParams[QUERY_PARAMS.FORWARD_URL]) {
            window.location.assign(queryParams[QUERY_PARAMS.FORWARD_URL])
        } else if (
            queryParams[QUERY_PARAMS.SHOW_LOGIN] === 'true' ||
            queryParams[QUERY_PARAMS.SHOW_LOGIN_LEGACY] === 'true'
        ) {
            openLoginModalAction(true)
        } else if (queryParams[QUERY_PARAMS.RESET_PASSWORD_CODE]) {
            setLoginModalChangePasswordAction()
            openLoginModalAction()
        }
    })

    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setLoaded(true)
    }, [])

    return (
        <div>
            {renderHero(heroImg, isXs, loaded, locale, isTestVariant)}
            {renderProgramsGrid(localePrograms)}
            {renderFooter(footerImg)}
            <ScrollTopArrow />
        </div>
    )
}

Home.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setLoginModalChangePasswordAction: PropTypes.func.isRequired,
    openLoginModalAction: PropTypes.func.isRequired,
    setLayoutConfig: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

export default connect(
    ({ user }) => ({
        user,
    }),
    {
        setLoginModalChangePasswordAction: setLoginModalChangePassword,
        openLoginModalAction: openLoginModal,
        setLayoutConfig: setConfig,
    }
)(Home)

export const query = graphql`
    {
        header: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/header.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        headerMobile: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/header_mobile.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 1400
                            srcSetBreakpoints: [1400, 1000]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        footer: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/footer.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1400, 1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        footerMobile: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/footer_mobile.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 1400
                            srcSetBreakpoints: [1400, 1000]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        footerTablet: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/footer_tablet.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 1400
                            srcSetBreakpoints: [1400, 1000]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        headerTest: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/ab-test/header.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        headerMobileTest: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/ab-test/header_mobile.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 1400
                            srcSetBreakpoints: [1400, 1000]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        footerTest: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/ab-test/footer.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1400, 1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        footerMobileTest: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/ab-test/footer_mobile.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 1400
                            srcSetBreakpoints: [1400, 1000]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        footerTabletTest: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/home/static/image/ab-test/footer_tablet.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 1400
                            srcSetBreakpoints: [1400, 1000]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        allProgram(sort: { order: ASC, fields: [sortIndex] }) {
            nodes {
                id
                durationWeeks
                headline
                locale
                level
                imageUrl
                sortIndex
                slug
                goal {
                    name
                    color
                }
                new
                details {
                    imagesNode {
                        background {
                            childImageSharp {
                                fluid(
                                    maxWidth: 965
                                    srcSetBreakpoints: [476, 965]
                                    toFormat: NO_CHANGE
                                    quality: 90
                                ) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
