import { useEffect, useState } from 'react'
import { GoogleOptimize } from '@gymondo/frontend-core/utils'
import { useSelector } from 'react-redux'

export const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState(null)
    const { consentChosen } = useSelector((state) => state.cookieConsent)

    useEffect(() => {
        ;(async () => {
            const experimentVariant = await GoogleOptimize.getVariant(
                experimentId
            )
            setVariant(experimentVariant)
        })()
    }, [consentChosen, experimentId])

    return variant
}
